import React from 'react';
import {
  TextField as MuiTextField,
  FormControl,
  InputLabel,
  FormControlLabel,
  Button,
  Checkbox as MuiCheckbox,
  Select as MuiSelect,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
} from '@material-ui/core';
import { useField } from 'formik';

export const Field = ({ as: Component, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Component
      error={meta.error && meta.touched}
      helperText={meta.error && meta.touched && <span>{meta.error}</span>}
      {...field}
      {...props}
    />
  );
};

export const TextField = props => (
  <Field
    as={MuiTextField}
    id={props.name}
    variant="outlined"
    margin="normal"
    fullWidth
    {...props}
  />
);

export const Select = props => (
  <FormControl variant="outlined">
    <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
    <Field as={MuiSelect} {...props} />
  </FormControl>
);

export const Checkbox = ({ label, alignTop, ...checkboxProps }) => {
  const { name } = checkboxProps;
  const [field, meta] = useField(name);
  const handleChange = event => {
    field.onBlur(event);
    field.onChange({
      target: { name, value: event.target.checked ? '1' : '0' },
    });
  };
  return (
    <>
      <FormControlLabel
        style={{ alignItems: alignTop ? 'flex-start' : 'center' }}
        control={
          <MuiCheckbox
            style={alignTop ? { paddingTop: 0 } : {}}
            {...field}
            {...checkboxProps}
            onChange={handleChange}
            checked={field.value === '1'}
          />
        }
        label={label}
      />
      {meta.error && meta.touched && (
        <FormHelperText error={meta.error && meta.touched}>
          {meta.error}
        </FormHelperText>
      )}
    </>
  );
};

export const FileUpload = ({ value, onChange, label, id, error }) => (
  <>
    <InputLabel htmlFor={id}>
      <Button variant="contained" color="primary" component="span">
        {value ? `${label} (${value.name})` : label}
      </Button>
      {error && (
        <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>
      )}
      <input
        type="file"
        id={id}
        style={{ display: 'none' }}
        onChange={event => onChange(event.target.files[0])}
      />
    </InputLabel>
  </>
);

export const RadioGroup = ({ label, options, ...props }) => {
  const [field, meta, helpers] = useField(props);
  console.log('formComponent - RadioGroup field:', field);
  console.log('formComponent - RadioGroup meta:', meta);
  return (
    <FormControl
      component="fieldset"
      error={meta.touched && meta.error}
      margin="normal"
    >
      <FormLabel component="legend">{label}</FormLabel>
      <MuiRadioGroup
        {...field}
        value={field.value || ''} // Ensure default value
        onChange={event => helpers.setValue(event.target.value)} // Explicit update for MUI v4
      >
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </MuiRadioGroup>
      {meta.touched && meta.error && (
        <FormHelperText>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};
