import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { Box, Button, Link as MuiLink } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { pink } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles(theme => ({
  root: {
    //maxWidth: 345,
    maxWidth: 300,
    flexGrow: 1,
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  button: {
    margin: 2,
  },
}));

export default function CannotShowOpps() {
  const classes = useStyles();

  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <br />
        <Avatar className={classes.pink}>
          <WarningIcon />
        </Avatar>
        <br />
        <Typography variant="h6" color="initial" component="span">
          You will not be able to view volunteer opportunities if:
        </Typography>
        <br />
        <Typography variant="body2" color="textSecondary" component="span">
          1. You have not signed the volunteer Code of Conduct on the profile
          page.
        </Typography>
        <br />
        <Typography variant="body2" color="textSecondary" component="span">
          2. You have not signed the accepted the use of your information on the
          profile page.
        </Typography>
        <br />
        <Typography variant="body2" color="textSecondary" component="span">
          3. You have not completed an orientation or your orientation
          attendance has not been verified.
        </Typography>
        <br />
        <Typography variant="body2" color="textSecondary" component="span">
          If you have questions or need assistance, please contact{' '}
          <a
            href="mailto:vsupport@calgaryfolkfest.com"
            style={{ textDecoration: 'none', color: 'blue' }}
          >
            vsupport@calgaryfolkfest.com
          </a>
          .
        </Typography>
        <br />

        <Box component="span" m={1}>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Link to="/userprofile">
                <Button
                  className={classes.heroButtons}
                  color="primary"
                  variant="contained"
                >
                  Return to Profile
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
